import get from 'lodash/get';
import pick from 'lodash/pick';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

export const MAX_ALLOWED_QUANTITY = 999999;
export const ITEM_TYPES = Object.freeze({
    LENS: 'LENS',
    ACCESSORY: 'ACCESSORY',
});

const defaultShoppingCart = () => ({
    shoppingCartId: null,
    shippingDetails: null,
    billingDetails: null,
    paymentDetails: null,
    agreedToTermsAndConditionsDate: null,
    items: [],
});

const state = () => ({
    loading: false,
    summary: null,
    itemsCount: 0,
    shippingMethods: [],
    shoppingCart: defaultShoppingCart(),
});

const getters = {
    /**
     * Gets the available shipping methods.
     *
     * @param {Object} state the Vuex state
     * @returns the shipping methods
     */
    shippingMethods(state) {
        return state.shippingMethods;
    },
    lensesCount({shoppingCart}) {
        return shoppingCart.lensItemsByPatientId.reduce(
            (accTotal, group) =>
                accTotal + group.items.reduce((acc, lens) => acc + lens.quantity, 0),
            0
        );
    },
    accessoriesCount({shoppingCart}) {
        return shoppingCart.items
            .filter((item) => item.itemType === ITEM_TYPES.ACCESSORY)
            .reduce((acc, accessory) => acc + accessory.quantity, 0);
    },
    itemsCount(_, {lensesCount, accessoriesCount}) {
        return lensesCount + accessoriesCount;
    },
    orderTotal({shoppingCart}) {
        return shoppingCart.items.reduce((acc, item) => acc + item.price * item.quantity, 0);
    },
    summary({summary, shoppingCart}, {itemsCount, orderTotal}) {
        var frieghtCharge = get(shoppingCart, 'shippingDetails.fixedFreightCharge', 0);
        if (frieghtCharge == null) {
            frieghtCharge = 0;
        }
        return (
            summary || {
                isCartSummary: true,
                itemsCount,
                orderTotal,
                limit: get(shoppingCart, 'creditLimit', 0),
                balance: 0,
                fixedFreightCharge: frieghtCharge, //get(shoppingCart, 'shippingDetails.fixedFreightCharge', 0),
            }
        );
    },
};

const mutations = {
    setShippingMethods(state, shippingMethods) {
        state.shippingMethods = shippingMethods;
    },
    setItemsCount(state, {totalItems}) {
        state.itemsCount = totalItems;
    },
    setItems(state, payload) {
        const items = get(payload, 'items', []).map((item) => ({
            ...item,
            quantityWrapper: item.quantity.toLocaleString('en-US'),
        }));
        state.shoppingCart = {...payload, items};
    },
    setSummary(state, payload) {
        state.summary = payload;
    },
    setLoading(state, value) {
        state.loading = value;
    },
};

const actions = {
    /**
     * Fetches the available shipping methods.
     *
     * @param {Object} object Vuex methods
     */
    async fetchShippingMethods({commit}) {
        let url = `shoppingcart/shippingmethods`;
        const {data} = await this._vm.$http.get(url);
        commit('setShippingMethods', data.results);
    },
    async fetchItemsCount({commit, rootState, rootGetters}) {
        const canLoadCartCount = checkPermissions(
            {[PERMISSIONS.ORDERING]: [PERMISSIONS_VALUES.READ_ONLY, PERMISSIONS_VALUES.READ_WRITE]},
            rootGetters['permissions/permissions'],
            rootState.user.currentUser.accessPermissions
        );
        if (!canLoadCartCount) return;
        const {
            data: {results},
        } = await this._vm.$http.get('shoppingcart/items/count');
        commit('setItemsCount', results);
        return results;
    },

    async fetchItems({commit}) {
        const {data} = await this._vm.$http.get('shoppingcart');
        commit('setItems', data.results);
        return data;
    },

    async fetchAvailableLenses({commit}, {side, payload}) {
        const {data} = await this._vm.$http.post(`shoppingcart/lenses`, payload);
        commit(
            'preopdata/setInventoryResults',
            {
                side,
                results: data.results,
            },
            {root: true}
        );
        return data;
    },

    async updateCheckout(_, payload) {
        const {data} = await this._vm.$http.post(`shoppingcart`, payload);
        return data;
    },

    async updateItem(_, payload) {
        const items = [
            pick(payload, [
                'shoppingCartItemId',
                'warehouseId',
                'toricInventoryId',
                'description',
                'quantity',
            ]),
        ];
        const {data} = await this._vm.$http.put(`shoppingcart/items`, {items});
        return data;
    },

    async removeItem({dispatch}, ids) {
        const {data} = await this._vm.$http.delete(`shoppingcart/items`, {params: {ids}});
        dispatch('fetchItems');
        dispatch('fetchItemsCount');
        return data;
    },

    async submitLenses(_, payload) {
        const {data} = await this._vm.$http.post('inventory/lenses', payload);
        return data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
